import { useRouter } from "next/navigation";

import Button from "@/components/ui/Button";
import useLocation from "@/lib/centra/hooks/useLocation";
import type { CartHook } from "@/lib/centra/hooks/useSelection/cart/useCart";
import useCart, {
  useCartStatus
} from "@/lib/centra/hooks/useSelection/cart/useCart";
import {
  convertCartToGTagItem,
  eventBeginCheckout
} from "@/lib/utils/Analytics/Functions";

import { PATHS } from "@/consts";
import Vouchers from "@/features/checkout/Vouchers";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./cart-summary.module.css";

const CartSummary = ({
  summary,
  modal,
  isFinalized
}: {
  summary: CartHook["cart"]["summary"] | undefined;
  modal?: CartHook["modal"];
  isFinalized?: boolean;
}) => {
  const router = useRouter();
  const t = useTranslations();
  const { currency } = useLocation();
  const { cart } = useCart();
  const { isPending } = useCartStatus();

  const goToCheckout = () => {
    if (modal) modal?.closeModal();

    router.push(PATHS.checkout.index);

    eventBeginCheckout({
      currency: currency || "",
      value: cart.summary?.prices.totalAsNumber,
      items: cart.items.map((item) => convertCartToGTagItem(item))
    });
  };
  const emptyCart = !cart.summary?.totalQuantity;

  return (
    <div
      className={clsx(
        styles.summary,
        modal && styles.summaryModal,
        isPending && styles.loading
      )}
    >
      <div className={styles.subtotals}>
        <div className={styles.subtotalShipping}>
          <span>
            <p>{t("checkout.summary.subtotal")}</p>
            <p>{summary?.prices?.total ?? "0,-"}</p>
          </span>

          <span>
            <p>{t("checkout.summary.shipping")}</p>
            <p>
              {!summary?.prices?.shippingAsNumber
                ? t("checkout.summary.free")
                : summary?.prices?.shipping ?? "0,-"}
            </p>
          </span>
        </div>

        {
          <span className={styles.total}>
            <p>{t("checkout.summary.total")}</p>
            <p>{summary?.prices?.grandTotal ?? "0,-"}</p>
          </span>
        }

        {!isFinalized && !modal && (
          <span className={styles.voucher}>
            <Vouchers />
          </span>
        )}
      </div>

      {!isFinalized && modal && (
        <div className={styles.cart__actions}>
          <div className={styles.cart__actions__inner}>
            <Button disabled={emptyCart} onClick={goToCheckout} full>
              <span className="uppercase">{t("checkout.summary.button")}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartSummary;
