"use client";
import clsx from "clsx";
import { default as Image } from "next/image";
import React, { Suspense, useMemo, useRef, useState } from "react";

import { FavouriteIcon } from "@/components/icons/FavouriteIcon";
import Plus from "@/components/icons/Plus";
import Button from "@/components/ui/Button";
import { PrefetchLink } from "@/components/ui/PrefetchLink";
import Price from "@/features/product/components/Price";
import { useWishlist, useWishlistProduct } from "@/features/wishlist/hooks";
import useDevice from "@/hooks/useDevice";
import type { ProductCard as PC } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { sizes } from "@/lib/utils/imageSizes";
import { useLocale, useTranslations } from "next-intl";
import { getProductLink } from "../../ProductProvider";
import { PriceSkeleton } from "../Price/Price";
import styles from "./index.module.css";

const ProductCard = ({
  product,
  related = false,
  className,
  priority,
}: {
  product: PC;
  related?: boolean;
  className?: string;
  priority?: boolean;
}) => {
  const locale = useLocale();
  const [activeVariant, setActiveVariant] = useState(0);
  const linkRef = useRef<HTMLAnchorElement>(null!);
  const t = useTranslations();
  const { mobile: isMobile } = useDevice();

  const hasDiscount = product?.description?.price?.[0].discountPercent !== 0;
  const discountPercentageString = `-${product?.description?.price?.[0].discountPercent}%`;

  const slug = product?.core?.slug ?? "#";

  if (!product) {
    return null;
  }

  const itemSet = new Set();
  const variants = product.variants?.filter((card) => {
    if (itemSet.has(card.itemId)) return false;
    itemSet.add(card.itemId);
    return true;
  });

  const variant = variants?.at(activeVariant);
  const name = product.core?.name;

  const hasTags =
    variants?.[activeVariant]?.comingSoon || variants?.[activeVariant]?.soldOut;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    /**
     * Prevent navigating to the product if a button or internal link is pressed
     */
    if (target.closest("a") || target.closest("button")) {
      return;
    }

    linkRef.current.click();
  };

  return (
    <article
      className={clsx(
        styles.card__container,
        related && styles.related,
        className,
      )}
    >
      <div onClick={handleClick} className={`product-card ${styles.card}`}>
        <PrefetchLink
          ref={linkRef}
          title={`${name} - ${variant?.variantName}`}
          href={
            activeVariant === 0
              ? getProductLink({ locale, slug })
              : getProductLink({
                  locale,
                  slug,
                  variantId: variant?.itemId,
                })
          }
          className={clsx(styles.card__image)}
        >
          {product?.labels && (
            <div className={styles.tags}>
              {product?.labels.map((label: string, key) => (
                <span className={clsx(styles.tag)} key={key}>
                  {label}
                </span>
              ))}
            </div>
          )}
          {variants &&
            variants.map((variant, index) => {
              const mainImage = variant.gallery?.image;
              const secondaryImage = variant.media?.[1];

              return (
                <div
                  key={index}
                  className={clsx(
                    styles.card__image__inner,
                    index === activeVariant && styles.active,
                  )}
                >
                  {hasDiscount && (
                    <>
                      <div className={styles.sale}>
                        {discountPercentageString}
                      </div>
                      <span className="sr-only">
                        {discountPercentageString}
                      </span>
                    </>
                  )}
                  {mainImage && (
                    <Image
                      src={mainImage.href}
                      alt={mainImage.alt}
                      fill
                      sizes={sizes(50)}
                      className={clsx(styles.product__image, styles.main)}
                      fetchPriority={index === 0 ? "high" : undefined}
                      priority={index === 0 ? priority : undefined}
                    />
                  )}
                  {secondaryImage && (
                    <Image
                      src={secondaryImage.href}
                      alt={secondaryImage.alt}
                      fill
                      sizes={sizes(50)}
                      className={clsx(styles.product__image, styles.secondary)}
                      fetchPriority={index === 0 ? "high" : undefined}
                      priority={index === 0 ? priority : undefined}
                    />
                  )}
                </div>
              );
            })}
        </PrefetchLink>
        <div className={styles.card__description}>
          {!related && (
            <div className={styles.addFavoriteWrapper}>
              <div className={styles.card__swatches}>
                <>
                  {variants?.map((variant, key) => {
                    if (key >= 5) return;
                    if (variants && variants.length > 5 && key === 4) {
                      return (
                        <Button
                          prefetch="hover"
                          mode="link"
                          className={styles.plusIcon}
                          href={getProductLink({
                            locale,
                            slug,
                            variantId: variant.itemId,
                          })}
                          key={key}
                        >
                          <Plus />
                        </Button>
                      );
                    } else {
                      return (
                        <PrefetchLink
                          title={variant.variantName}
                          href={getProductLink({
                            locale,
                            slug,
                            variantId: variant.itemId,
                          })}
                          key={key}
                          className={clsx(
                            styles.swatch,
                            key === activeVariant && styles.active,
                          )}
                          onMouseEnter={() => {
                            !isMobile && setActiveVariant(key);
                          }}
                        >
                          <span
                            style={{ background: `${variant?.swatch?.hex}` }}
                          />
                        </PrefetchLink>
                      );
                    }
                  })}
                </>
              </div>
              {product.id && <WishlistButton product={product} />}
            </div>
          )}
          <p className={styles.product__material}>{name}</p>
          <div className={styles.product__meta}>
            {!!hasTags && (
              <div className={styles.tags}>
                {product?.core?.isNew && (
                  <div className={clsx(styles.card__tag)}>
                    <p>{t("product.tags.new")}</p>
                  </div>
                )}
                {variants?.[activeVariant]?.soldOut &&
                  !variants?.[activeVariant]?.comingSoon && (
                    <div className={clsx(styles.card__tag, styles.soldOutTag)}>
                      <p>{t("product.tags.soldOut")}</p>
                    </div>
                  )}
                {variants?.[activeVariant]?.comingSoon &&
                  !product?.description?.price?.[0].productOnSale && (
                    <div
                      className={clsx(styles.card__tag, styles.commingSoonTag)}
                    >
                      {t("product.tags.comingSoon")}
                    </div>
                  )}
              </div>
            )}
            <Suspense fallback={<PriceSkeleton />}>
              <Price
                prices={variant?.prices}
                isSoldOut={variant?.soldOut}
                className={clsx(
                  styles["product-price"],
                  "uppercase",
                  "truncate",
                )}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProductCard;

const WishlistButton = ({ product }: { product: PC }) => {
  const id = useMainWishlistId(product);
  const wishlist = useWishlistProduct(id);
  return (
    <Button
      mode="icon"
      aria-label="Add to wishlist"
      className={styles.addButton}
      onClick={wishlist.has ? wishlist.remove : wishlist.add}
    >
      <div
        className={clsx(
          wishlist.has ? styles.removeFavourite : styles.addFavourite,
        )}
      >
        <FavouriteIcon />
      </div>
    </Button>
  );
};

/**
 * Centra does not add the correct variant id to the wishlist, so we need another check for each variant in a product
 */
const useMainWishlistId = (product: PC) => {
  const items = useWishlist().items;

  const mainVariant = useMemo(() => {
    return product?.variants?.find((variant) =>
      items.includes(variant.itemId || product.id || ""),
    );
  }, [product, items]);

  return mainVariant?.itemId || product?.id;
};
