"use client";

import { PDPData } from "@/lib/centra/fetchersSetup";
import { usePathname, useSearchParams } from "next/navigation";
import React, { createContext, useContext, useMemo } from "react";
import { ProductVariant } from "../../lib/centra/localAtomics";

type ProductContext = {
  activeVariantId?: string;
  setActiveVariant: (variantId: string | undefined) => void;
  activeVariant?: ProductVariant;
  product: PDPData;
};

export const VARIANT_QUERY_KEY = "variant";
const ProductContext = createContext<ProductContext>(null!);

type ProductProviderProps = {
  initialActiveVariant?: string;
  productData: PDPData;
  children: React.ReactNode;
};

/**
 * Helper functions for human readable URL variants
 */
const urlVariant = {
  create: (variantId?: string, name?: string) => {
    return `${encodeURIComponent(name || "")}-${variantId}`;
  },
  getId: (variant: string) => {
    return variant.split("-").pop();
  },
};

export const ProductProvider = ({
  children,
  initialActiveVariant,
  productData,
}: ProductProviderProps) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const activeVariantId =
    searchParams.get(VARIANT_QUERY_KEY) || initialActiveVariant;

  const activeVariant = useMemo(() => {
    return (
      productData?.variants?.find((item) => item.itemId === activeVariantId) ??
      productData?.variants?.[0]
    );
  }, [productData, activeVariantId]);

  const setActiveVariant = (variantId: string | undefined) => {
    const variant = productData?.variants?.find(
      (item) => item.itemId === variantId,
    );
    if (!variant || !variantId) return;

    const newParams = new URLSearchParams(searchParams);
    newParams.set(VARIANT_QUERY_KEY, variantId);

    window.history.replaceState({}, "", `${pathname}?${newParams.toString()}`);
  };

  return (
    <ProductContext.Provider
      value={{
        activeVariantId,
        setActiveVariant,
        activeVariant,
        product: productData,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);

export function getProductLink({
  locale,
  slug,
  variantId,
}: {
  locale: string;
  slug: string;
  variantId?: string;
}) {
  const base = `/${locale}/product/${slug}`;
  if (variantId) {
    return `${base}?${VARIANT_QUERY_KEY}=${variantId}`;
  }
  return base;
}
