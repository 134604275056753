// ref - https://www.notion.so/frenddigital/Headless-UI-Primitiver-8a6b39f91e7b4e9eb2839fb6769ae95b?pvs=4#97a42a6a27244faca9b55bb440027d1b

import { JSXElementConstructor, forwardRef } from "react";

type ValidClasses = string | boolean | undefined | null;

const merge = (arr: ValidClasses[]) => arr.filter(Boolean).join(" ");

/**
 * Creates a a wrapper of the provided element and attaches
 * the classNames provided.
 * Provides full typescript support
 */
export function classed<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
>(Component: T, ...classNames: ValidClasses[]) {
  const Inner = forwardRef<React.ElementRef<T>, React.ComponentProps<T>>(
    ({ className, ...props }, ref) => {
      return (
        <Component
          className={merge([className, ...classNames])}
          {...(props as any)}
          ref={ref}
        />
      );
    }
  );

  Inner.displayName =
    (Component as any).displayName ||
    (Component as any).name ||
    (typeof Component === "string" ? Component : "ClassedComponent");

  return Inner;
}
