"use client";

import { useCart } from "@/lib/centra/hooks/useSelection";

import { PATHS } from "@/consts";
import CartItems from "@/features/cart/components/CartItems/CartItems";
import CartSummary from "@/features/cart/components/CartSummary/CartSummary";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRemovedProduct } from "../../cartStore";
import { CartItemToast } from "../CartItem/CartItemToast";
import CartUpsell from "../CartUpsell/CartUpsell";
import EmptyCart from "./EmptyCart";
import styles from "./index.module.css";

const CartContent = () => {
  const { items, modal, cart } = useCart();
  const t = useTranslations();
  const removedItem = useRemovedProduct((state) => state.item);

  if (!cart.items.length) {
    return (
      <div className={styles.cartContent}>
        <div>
          {removedItem && <CartItemToast item={removedItem} />}
          {!removedItem && (
            <EmptyCart
              text={t("modal.emptycart")}
              linkText={t.rich("modal.exploreCollections", {
                link: (chunks) => <Link href={PATHS.products}>{chunks}</Link>
              })}
            />
          )}
          <CartUpsell />
        </div>
        <CartSummary summary={cart.summary} modal={modal} />
      </div>
    );
  }

  return (
    <div className={styles.cartContent}>
      <div>
        <CartItems items={items} />
      </div>
      <CartSummary summary={cart.summary} modal={modal} />
      <CartUpsell />
    </div>
  );
};

export default CartContent;
