import { FormattedSelection } from "@/lib/centra/formatters/formatSelection";
import { create } from "zustand";

export type CentraSelectionItem = NonNullable<
  FormattedSelection["rawSelection"]["items"]
>[number];

export type RemovedProduct = {
  id: string;
  quantity: number;
  index: number;
  optimisticItem?: CentraSelectionItem;
};

interface RemovedProductsStore {
  item: null | RemovedProduct;
  addItem: (removedProduct: RemovedProduct) => void;
  clearItem: () => void;
}

export const useRemovedProduct = create<RemovedProductsStore>((set) => ({
  item: null,
  addItem: (item) => set(() => ({ item: item })),
  clearItem: () => set(() => ({ item: null }))
}));

export const getItemByLine = (selection: FormattedSelection, line: string) => {
  const itemIndex = selection.selection.items?.findIndex(
    (data) => data.line === line
  );

  if (itemIndex === -1 || typeof itemIndex !== "number") {
    return;
  }
  const item = selection.selection.items?.[itemIndex];

  const rawItem = selection.rawSelection.items?.[itemIndex];

  if (!item || !rawItem) return;
  return { item, rawItem, index: itemIndex };
};
