"use client";
import Modal from "@/components/ui/Modal";
import Cart from "@/features/cart/components/Cart";
import useModal from "@/hooks/useModal";
import { useCart } from "@/lib/centra/hooks/useSelection";
import { useRemovedProduct } from "../../cartStore";
import styles from "./index.module.css";

const CartModal = () => {
  const { cart, isLoading } = useCart();
  const clearRemovedItem = useRemovedProduct((state) => state.clearItem);
  const handleClose = () => {
    clearRemovedItem();
  };

  return (
    <Modal
      onClose={handleClose}
      modal={"cart"}
      className={styles.cart}
      title={`${cart.totals?.totalQuantity ? "Your cart (" + cart.totals.totalQuantity + ")" : "Your cart"}`}
      market={true}
      always
    >
      {!isLoading && <Cart />}
    </Modal>
  );
};

export default CartModal;

export const useCartModal = () => {
  return useModal("cart", { lock: true });
};
