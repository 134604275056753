import { useEffect, useLayoutEffect } from "react";

/*
 * useIsomorphicLayoutEffect
 *
 * @description
 * "In the browser, I'm an `useLayoutEffect`, but in SSR, I'm an `useEffect`."
 * - said the hook.
 */

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;
