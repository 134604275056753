"use client";

import { useCartModal } from "@/features/cart/components/CartModal/CartModal";
import useStore from "@/lib/stateManagement/persistedState/persistedStore";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import useSelection from "../useSelection";
import useRemoveFromCart from "./removeFromCart";
import useRemovePromo from "./removePromo";
import useUpdateQuantity from "./updateQuantity";
import { useAddPromo } from "./useAddPromo";
import useAddToCart from "./useAddToCart";

const useCart = () => {
  const local_token = useStore((state) => state.token);
  const { cart, isLoading, data, refetch, isFetching } = useSelection();

  const modal = useCartModal();
  const { addToCart } = useAddToCart();
  const { addPromo } = useAddPromo();
  const { removePromo } = useRemovePromo();
  const { removeItems } = useRemoveFromCart();
  const { updateQuantity } = useUpdateQuantity();

  const changeShippingMethod = async (shippingMethod: string) => {
    if (!local_token) {
      console.error("Missing customer token key");
      throw new Error("Missing customer token key");
    }
    const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
    if (window.CentraCheckout) window.CentraCheckout.suspend();
    const res = await fetch(`${apiUrl}/shipping-methods/${shippingMethod}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "API-Token": local_token || ""
      }
    });
    const data = await res.json();
    if (window.CentraCheckout) window.CentraCheckout.resume();
    refetch();
    return data;
  };

  // useEffect(() => {
  //   if (!data?.selection?.shippingMethod && cart?.shippingMethods?.length) {
  //     changeShippingMethod(cart?.shippingMethods[0].shippingMethod);
  //   }
  // }, [])

  return {
    cart: cart,
    items: cart?.items,
    isLoading,
    isFetching,
    addItem: addToCart,
    addPromo,
    removePromo,
    removeItems,
    updateQuantity,
    modal,
    summary: cart?.summary,
    shippingMethods: cart?.shippingMethods,
    currentShippingMethod: data?.selection?.shippingMethod,
    changeShippingMethod
  };
};

export type CartHook = ReturnType<typeof useCart>;

export default useCart;

/**
 * Is the cart in a pending transition. Used to disable btns and show loading states.
 * Differs from isLoading as this is a combination of both selection and action (addToCart,quantity,etc) loading states
 */
export const useCartStatus = () => {
  const removePromo = useIsMutating({
    mutationKey: ["removePromo"]
  });
  const addPromo = useIsMutating({
    mutationKey: ["addPromo"]
  });
  const addToCart = useIsMutating({
    mutationKey: ["addToCart"]
  });
  const removeFromCart = useIsMutating({
    mutationKey: ["removeFromCart"]
  });
  const quantityPendig = useIsMutating({
    mutationKey: ["updateQuantity"]
  });
  const selectionFetching = useIsFetching({
    queryKey: ["selection"]
  });

  const isPending =
    !!removePromo ||
    !!addPromo ||
    !!addToCart ||
    !!removeFromCart ||
    !!quantityPendig ||
    !!selectionFetching;

  return { isPending };
};
