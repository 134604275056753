const maxMobile = 769;
const maxDesktop = 2560;

export const sizes = (mobile: number, desktop?: number) => {
  const queries = [`(max-width: ${maxMobile}px) ${mobile}vw`];

  if (desktop) {
    // remember to update this to the max-width of the site
    // if it ever changes to a fixed size below 100 %
    queries.push(`(max-width: ${maxDesktop}px) ${desktop}vw`);
  }

  return queries.join(", ");
};
