const Close = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 5L5 27"
        stroke="#161616"
        style={{ stroke: "var(--svg-color)" }}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M27 27L5 5"
        stroke="#161616"
        style={{ stroke: "var(--svg-color)" }}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Close;
