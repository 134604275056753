import CartItem from "@/features/cart/components/CartItem/CartItem";

import { ICartItem } from "@/lib/centra/formatters/types";
import clsx from "clsx";
import { useMemo } from "react";
import { RemovedProduct, useRemovedProduct } from "../../cartStore";
import { CartItemToast } from "../CartItem/CartItemToast";
import styles from "./cart-items.module.css";

interface ICartItemsProps {
  items: ICartItem[] | null;
  isFinalized?: boolean;
}

type RemovedProductExtended = {
  removed: true;
} & RemovedProduct;

type ItemsWithRemoved = Array<ICartItem | RemovedProductExtended>;

const CartItems = ({ items, isFinalized }: ICartItemsProps) => {
  const removedItem = useRemovedProduct((state) => state.item);
  const itemsWithRemoved = useMemo<ItemsWithRemoved>(() => {
    if (!removedItem) {
      return items || [];
    }

    const newItems: ItemsWithRemoved = [...(items || [])];

    const itemAtPosition = items?.[removedItem.index];
    let deleteCount = 0;
    if (itemAtPosition?.itemId === removedItem.id) {
      deleteCount = 1;
    }

    newItems.splice(removedItem.index, deleteCount, {
      ...removedItem,
      removed: true
    });

    return newItems;
  }, [items, removedItem]);
  return (
    <div className={clsx(styles.container, isFinalized && styles.finalized)}>
      {itemsWithRemoved?.map((item, index) => {
        if ("removed" in item) {
          return (
            <CartItemToast
              item={item}
              key={item.optimisticItem?.line || item.id}
            />
          );
        }

        return (
          <CartItem
            index={index}
            item={item}
            key={item.line}
            isFinalized={isFinalized}
          />
        );
      })}
    </div>
  );
};

export default CartItems;
