"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setCartItemQuantity } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import { getItemByLine, useRemovedProduct } from "@/features/cart/cartStore";
import { FormattedSelection } from "@/lib/centra/formatters/formatSelection";
import optimisticQuantityUpdate from "../utils/optimisticUpdate";

export interface IUpdateQuantityVariables {
  line: string;
  newQuantity: number;
}

export default function useUpdateQuantity() {
  const clearRemovedItem = useRemovedProduct((state) => state.clearItem);
  const addRemovedItem = useRemovedProduct((state) => state.addItem);
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);

  const updateQuantity = useMutation({
    mutationKey: ["updateQuantity"],
    mutationFn: (variables: IUpdateQuantityVariables) =>
      setCartItemQuantity(variables, customerToken),
    onMutate: async (variables) => {
      if (variables.newQuantity !== 0) clearRemovedItem();
      if (window.CentraCheckout) window.CentraCheckout.suspend();
      queryClient.cancelQueries({ queryKey: ["selection"] });

      // Snapshot the previous value
      const oldData = queryClient.getQueryData<FormattedSelection>([
        "selection"
      ]);

      if (!oldData) return { oldData };

      if (variables.newQuantity === 0) {
        const foundItem = getItemByLine(oldData, variables.line);
        if (foundItem) {
          addRemovedItem({
            optimisticItem: { ...foundItem.rawItem, quantity: 1 },
            id: foundItem.item.itemId,
            index: foundItem.index,
            quantity: 1
          });
        }
      }

      //  Optimistically update to the new value
      queryClient.setQueryData(["selection"], (oldCart: FormattedSelection) => {
        const updatedCart = optimisticQuantityUpdate(oldCart, {
          line: variables.line,
          newQuantity: variables.newQuantity
        });

        return updatedCart;
      });

      // Return a context object with the snapshotted value
      return { oldData };
    },

    onError: (error, _variables, context) => {
      console.log(error);
      if (context?.oldData) {
        queryClient.setQueryData(["selection"], context?.oldData);
      }
    },

    onSettled: () => {
      if (window.CentraCheckout) window.CentraCheckout.suspend();
      queryClient.invalidateQueries({ queryKey: ["selection"] });
    }
  });
  return { updateQuantity };
}

export type UpdateQuantity = ReturnType<
  typeof useUpdateQuantity
>["updateQuantity"];
