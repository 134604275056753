"use client";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { createContext } from "react";

type Props = {
  children?: React.ReactNode;
};

interface DeviceContext {
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
}

export const DeviceContext = createContext<DeviceContext>(null!);

const DeviceProvider: React.FC<Props> = ({ children }) => {
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 999px)");
  const isMobile = useMediaQuery("(max-width: 599px)");

  return (
    <DeviceContext.Provider value={{ isDesktop, isMobile, isTablet }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
