"use client";
import Close from "@/components/icons/Close";
import Button from "@/components/ui/Button";
import useDevice from "@/hooks/useDevice";
import useModal from "@/hooks/useModal";
import useLocation from "@/lib/centra/hooks/useLocation";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { Suspense } from "react";
import ReactCountryFlag from "react-country-flag";
import { DrawerHeader, DrawerRoot, DrawerViewport } from "./Drawer";
import drawerstyles from "./Drawer/index.module.css";
import styles from "./index.module.css";
export const LOCATION_SETTINGS_MODAL = "location-settings";

type Props = {
  toolbar?: boolean | React.ReactNode;
  footer?: React.ReactNode;
  unstyled?: boolean;
  className?: string;
  modal: string;
  title?: string;
  [key: string]: any;
  direction?: "left" | "right";
  mobileonly?: boolean;
  market?: boolean;
  onClose?: () => void;
  key?: string;
  popover?: boolean;
};

const Modal = ({
  children,
  modal,
  className,
  toolbar = true,
  title,
  footer,
  mobileonly,
  direction = "right",
  market = false,
  popover,
  onClose
}: Props) => {
  const { isOpen: open, closeModal, setOpen } = useModal(modal, { popover });
  const { desktop } = useDevice();
  const location = useLocation();
  const t = useTranslations();
  if (mobileonly && desktop) return null;
  return (
    <DrawerRoot
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        !open && onClose?.();
      }}
    >
      <DrawerViewport
        className={
          direction === "left" ? drawerstyles.left : drawerstyles.right
        }
      >
        <div className={clsx(styles.modalInner, className)}>
          <div className={styles.modalContent}>
            {typeof toolbar === "boolean" && !!toolbar ? (
              <DrawerHeader className={clsx(className)}>
                <header className={styles.modal__toolbar}>
                  <>
                    {title && <span className={styles.title}>{title}</span>}

                    <div className={styles.modal__toolbar_actions}>
                      {market && (
                        <Button
                          className={styles.locationButton}
                          popover
                          modal={"location"}
                          color="transparent"
                        >
                          <span className={styles.countryFlag}>
                            <ReactCountryFlag
                              alt={location?.location}
                              countryCode={"" + location?.location}
                              svg
                            />
                            <span>{t("footer.location.button.change")}</span>
                          </span>
                        </Button>
                      )}
                      <Button onClick={closeModal} mode="icon">
                        <Close />
                      </Button>
                    </div>
                  </>
                </header>
              </DrawerHeader>
            ) : (
              toolbar
            )}
            <Suspense>{children}</Suspense>
            {footer && <footer className={styles.modalFooter}>{footer}</footer>}
          </div>
        </div>
      </DrawerViewport>
    </DrawerRoot>
  );
};

export default Modal;
