import { use } from "react";

import { DeviceContext } from "@/lib/utils/Device/Device";

const useDevice = () => {
  const { isDesktop, isMobile, isTablet } = use(DeviceContext);

  return {
    mobile: isMobile,
    isTablet: isTablet,
    desktop: isDesktop
  };
};

export default useDevice;

export type DeviceType = ReturnType<typeof useDevice>;
