import clsx from "clsx";
import formStyles from "../Checkout/checkout.module.css";
import type { InputProps } from "./Input";
import styles from "./input.module.css";

interface UIProps extends InputProps {
  conditionalStyle?: string;
}

const UI = (props: UIProps) => {
  const {
    type,
    placeholder,
    value,
    onChange,
    className,
    disabled = false,
    conditionalStyle = "",
    message = "",
    required = false,
    name,
    autocomplete
  } = props;
  return (
    <div className={clsx(styles.inputWrap, value && styles.touched)}>
      <label
        className={`${className} ${formStyles.label} ${styles.label} ${conditionalStyle}`}
        htmlFor={name}
      >
        {placeholder}
        {required && <span className={styles.required}> *</span>}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        autoComplete={autocomplete}
        value={value}
        onChange={onChange}
        className={`${styles.input} ${conditionalStyle}`}
        disabled={disabled}
      />
      {props?.children}
      <p className={`${conditionalStyle} ${styles.message}`}>{message} </p>
    </div>
  );
};

export default UI;
