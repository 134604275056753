import { useEffect } from "react";
import { create } from "zustand";
import { StorageValue, persist } from "zustand/middleware";

export interface LocalWishlistStore {
  items: Set<string>;
  removed: Set<string>;
  add: (itemId?: string) => void;
  remove: (itemId?: string) => void;
  clear: () => void;
  toggle: (itemId: string) => void;

  restoreLastRemoved: () => void;
}

const storage = {
  getItem: (name: string) => {
    const str = localStorage.getItem(name);
    if (!str) return null;
    const { state } = JSON.parse(str);
    return {
      state: {
        ...state,
        items: new Set(state.items)
      }
    };
  },
  setItem: (name: string, value: StorageValue<LocalWishlistStore>) => {
    const str = JSON.stringify({
      state: {
        ...value.state,
        items: Array.from(value.state.items.values())
      }
    });

    localStorage.setItem(name, str);
  },
  removeItem: (name: string) => {
    localStorage.removeItem(name);
  }
};

export const useLocalWishlist = create(
  persist<LocalWishlistStore>(
    (set) => ({
      items: new Set(),
      removed: new Set(),
      add: (itemId) => {
        if (!itemId) return;
        set((state) => {
          const items = new Set(state.items);
          items.add(itemId);
          return { items };
        });
      },
      clear: () => {
        set({ items: new Set() });
      },
      remove: (itemId) => {
        if (!itemId) return;
        set((state) => {
          const items = new Set(state.items);
          items.delete(itemId);
          const removed = new Set(state.removed);
          removed.add(itemId);
          return { items, removed };
        });
      },
      restoreLastRemoved: () => {
        set((state) => {
          const items = new Set(state.items);
          const removed = new Set(state.removed);
          const last = Array.from(removed).pop();
          if (last) {
            items.add(last);
            removed.delete(last);
          }
          return { items, removed };
        });
      },
      toggle: (itemId: string) => {
        set((state) => {
          const items = new Set(state.items);
          if (items.has(itemId)) {
            items.delete(itemId);
          } else {
            items.add(itemId);
          }
          return { items };
        });
      }
    }),
    {
      name: "wishlist",
      partialize: ({ removed, ...items }) =>
        ({ ...items }) as LocalWishlistStore,
      skipHydration: true,
      storage
    }
  )
);

export const useHydrateLocalWishlist = () => {
  useEffect(() => {
    if (useLocalWishlist.persist.hasHydrated()) return;

    useLocalWishlist.persist.rehydrate();
  }, []);
};
