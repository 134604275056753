export const PATHS = {
  home: "/",
  account: "/account",
  login: "/login",
  register: "/register",
  products: "/products",
  explore: "/explore",
  checkout: {
    index: "/checkout",
    summary: "/checkout/summary"
  },
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy"
};

export const AUTH_REQUIRED_PATHS = [PATHS.account];

/**
 * Maximum amount of products to show on the product listing page
 */
export const PRODUCT_LISTING_COUNT = 32;
