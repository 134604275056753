import { classed } from "@/lib/utils/classed";
import {
  DialogClose,
  DialogContent,
  DialogRoot,
  DialogTrigger,
  DialogViewport
} from "../Dialog";
import styles from "./index.module.css";

export const DrawerRoot = DialogRoot;
export const DrawerViewport = classed(DialogViewport, styles.drawer);
export const DrawerContent = classed(DialogContent, styles.drawerContent);
export const DrawerHeader = classed("header", styles.header);
export const DrawerTrigger = DialogTrigger;
export const DrawerClose = classed(DialogClose, styles.close);
