import Button from "@/components/ui/Button";

import clsx from "clsx";
import Image from "next/image";

import { ICartItem } from "@/lib/centra/formatters/types";
import useRemoveFromCart from "@/lib/centra/hooks/useSelection/cart/removeFromCart";
import { useTranslations } from "next-intl";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import styles from "./index.module.css";

interface ICartItemProps {
  item: ICartItem;
  isFinalized?: boolean;
  index: number;
}

const CartItemPrice = ({
  discountedPrice,
  price,
}: {
  discountedPrice?: string;
  price?: string;
}) => {
  const isDiscounted = discountedPrice !== price;
  return (
    <div className={clsx(styles.discounted__wrapper)}>
      {isDiscounted && (
        <span className={styles.discounted__price}>{discountedPrice}</span>
      )}
      <p className={styles.regular__price}>{price}</p>
    </div>
  );
};

const CartImage = ({ item }: ICartItemProps) => {
  return (
    <div className={styles.image}>
      {item.media.full?.href && (
        <Image
          src={item?.media?.full?.href || ""}
          width={64}
          height={85}
          alt={""}
        />
      )}
    </div>
  );
};

const CartItemQuantity = ({ item }: ICartItemProps) => {
  if (!item) return null;

  return (
    <div className={styles.quantity__wrapper}>
      <QuantitySelector
        quantity={item.quantity}
        totalProductQuantity={item.totalProductQuantity || item.quantity}
        line={item.line}
      />
    </div>
  );
};

const DeleteCartItem = ({ item }: ICartItemProps) => {
  const { removeItems } = useRemoveFromCart();
  const remove = (item: ICartItem) => {
    return removeItems.mutate([
      {
        line: item.id,
        numberOfItemsToRemove: item.quantity,
        totalProductQuantity: item.quantity,
      },
    ]);
  };

  if (!item) return null;

  return (
    <Button mode="link" onClick={() => remove(item)}>
      <span>
        <span className={styles.removeLabel}>Remove</span>
      </span>
    </Button>
  );
};

const CartItem = ({ item, isFinalized, index }: ICartItemProps) => {
  const t = useTranslations();

  return (
    <div className={clsx(styles.item, isFinalized && styles.finalized)}>
      <CartImage item={item} index={index} />

      <div className={styles.details__container_wrap}>
        <div className={styles.item__info}>
          <div className={styles.details__container}>
            <div className={styles.left__column}>
              <p className={styles.item__name}>{item?.name}</p>
              <div className={styles.item__meta}>
                <span>{item.size}</span> / <span>{item.variant}</span>
              </div>
            </div>

            <div className={styles.right__column}>
              <CartItemPrice
                discountedPrice={item.discountedPrice}
                price={item.price}
              />
            </div>
          </div>
        </div>

        <div className={styles.item__actions}>
          {isFinalized ? (
            <span>
              {t("components.cartItem.quantity", { quantity: item.quantity })}
            </span>
          ) : (
            <>
              <CartItemQuantity index={index} item={item} />
              <DeleteCartItem index={index} item={item} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
