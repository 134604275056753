"use client";
import { ReactNode } from "react";
import styles from "./index.module.css";

type Props = {
  text?: ReactNode;
  linkText?: ReactNode;
};

const EmptyCart = ({ text, linkText }: Props) => {
  return (
    <div className={styles.emptyCart}>
      <p>{text}</p>
      <span>{linkText}</span>
    </div>
  );
};

export default EmptyCart;
