import { useCallback, useRef } from "react";

type Fn = (...arg: any[]) => void;
/**
 * Creates a debounced version of the provided callback function
 */
export const useDebouncedCallback = <T extends Fn>(
  func: T,
  wait: number
): T => {
  const timeout = useRef<NodeJS.Timeout>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<T>(
    ((...args: Parameters<T>) => {
      const callback = () => {
        clearTimeout(timeout.current);
        func(...args);
      };
      clearTimeout(timeout.current);
      timeout.current = setTimeout(callback, wait);
    }) as unknown as T,
    [func, wait]
  );
};
