import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="var(--svg-color)"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M29.723 11.85c0-3.785-3.403-6.85-6.806-6.85-2.552 0-5.104 1.712-6.806 4.28C14.41 6.713 11.858 5 9.306 5 5.903 5 2.5 8.065 2.5 11.85c0 2.174.936 4.246 2.57 5.684l11.041 9.727 11.043-9.727a7.577 7.577 0 0 0 2.569-5.685Z"
    />
  </svg>
);
export { SvgComponent as FavouriteIcon };
