"use client";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addToCart as addProductToCart } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import {
  CentraSelectionItem,
  useRemovedProduct
} from "@/features/cart/cartStore";
import { FormattedSelection } from "@/lib/centra/formatters/formatSelection";
import { optimisticAddToCart } from "../utils/optimisticUpdate";

export default function useAddToCart() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");
  const clearRemoved = useRemovedProduct((state) => state.clearItem);
  const customerToken = usePersistedStore((state) => state.token);

  const addToCart = useMutation({
    mutationKey: ["addToCart"],
    mutationFn: async (variables: {
      itemId: string;
      quantity?: number;
      cartItem?: CentraSelectionItem;
      comment?: string;
    }) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      return await addProductToCart(variables, customerToken).catch(
        () => undefined
      );
    },

    onMutate: async (variables) => {
      clearRemoved();
      const oldData = queryClient.getQueryData<FormattedSelection>([
        "selection"
      ]);
      if (!oldData) queryClient.invalidateQueries({ queryKey: ["selection"] });

      try {
        variables.cartItem &&
          oldData &&
          queryClient.setQueryData(["selection"], () => {
            const update = optimisticAddToCart(oldData!, {
              itemId: variables.itemId,
              cartItem: variables.cartItem!
            });
            return update;
          });
      } catch (error) {
        console.error(error);
      }

      return { oldData };
    },

    onError: async (error, _variables, context) => {
      console.error(error);
      return queryClient.setQueryData(["selection"], context?.oldData);
    },

    onSuccess: (response) => {
      if (!response) return;
      return queryClient.setQueryData<FormattedSelection>(
        ["selection"],
        response
      );
    },

    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ["selection"] });
    }
  });
  return { addToCart };
}

export type AddToCart = ReturnType<typeof useAddToCart>["addToCart"];
