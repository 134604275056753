"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PATHS } from "@/consts";
import { resetPassword } from "@/lib/centra/selectionFunctions";
import { useRouter } from "next/navigation";

export default function useResetPassword() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const setNewPassword = useMutation({
    mutationFn: (variables: { newPassword: string; i: any; id: any }) =>
      resetPassword(variables),
    onError: async (error: Error) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();
      return error;
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ["selection"]
      });
      router.push(PATHS.account);
    }
  });

  return { setNewPassword };
}
