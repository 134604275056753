import type { VoucherHook } from "@/lib/types/centra";

import Minus from "@/components/icons/Minus";
import Plus from "@/components/icons/Plus";
import Button from "@/components/ui/Button";
import clsx from "clsx";
import Input from "../Input";
import styles from "./vouchers.module.css";

const UI = (props: { voucher: VoucherHook }) => {
  const { voucher } = props;
  const {
    code,
    add,
    remove,
    activateInput,
    deactivateInput,
    showInput,
    showRemove,
    error,
    updateCode,
    isValid
  } = voucher;

  if (showInput)
    return (
      <div className={styles.wrap}>
        <Button
          className={styles["add-voucher"]}
          onClick={deactivateInput}
          mode="unset"
        >
          <span>
            <span>Add a voucher</span>
            <Minus />
          </span>
        </Button>
        <form className={styles.form} onSubmit={add}>
          <Input
            placeholder="Voucher"
            value={code}
            onChange={updateCode}
            isValid={isValid}
          />
          <span className={styles.buttons}>
            <Button>Apply</Button>
          </span>
        </form>

        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  if (showRemove)
    return (
      <div className={clsx(styles.activeVoucher, styles.container)}>
        <span>
          <p>Add a voucher</p>
        </span>
        <span className={styles.subContainer}>
          <span className={styles.codeContainer}>
            <p className={styles.voucherLabel}>Voucher</p>
            <p className={styles.voucherCode}>{code}</p>
          </span>
          <Button onClick={remove} className={styles.buttons}>
            Remove
          </Button>
        </span>
      </div>
    );
  return (
    <Button
      mode="unset"
      className={styles["add-voucher"]}
      onClick={activateInput}
    >
      <span>
        <span>Add a voucher</span>
        <Plus />
      </span>
    </Button>
  );
};

export default UI;
