import useAddToCart from "@/lib/centra/hooks/useSelection/cart/useAddToCart";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { RemovedProduct } from "../../cartStore";
import styles from "../Cart/index.module.css";

type Props = {
  item: RemovedProduct;
};

export const CartItemToast = ({ item }: Props) => {
  const { addToCart } = useAddToCart();
  const handleClick = () => {
    addToCart.mutate({
      itemId: item.id,
      quantity: item.quantity,
      cartItem: item.optimisticItem
    });
  };
  const t = useTranslations("cart");
  return (
    <div className={clsx(styles.emptyCart, "cart-toast")}>
      <p>{t("removedItem")}</p>
      <button onClick={handleClick}>{t("restore")}</button>
    </div>
  );
};
