import { useSelection } from "@/lib/centra/hooks/useSelection";

export const useAuth = () => {
  const selection = useSelection();

  return {
    token: selection.data?.token,
    isLoading: selection.isLoading,
    isLoggedIn: !!selection.loggedIn,
    user: selection.loggedIn
  };
};
