import type { ProductCard as ProductCardType } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { useCart } from "@/lib/centra/hooks/useSelection";
import { serverFetchRelatedPC } from "@/lib/centra/serverFunctionsCentra";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import styles from "./index.module.css";

import ProductCard from "@/features/product/components/ProductCard";
import clsx from "clsx";

const useUpsell = () => {
  const { items } = useCart();
  const relatedIds = items.map((item) => item.productId);

  const { data } = useQuery({
    queryKey: ["upsellProducts", relatedIds],
    queryFn: () => serverFetchRelatedPC(relatedIds, "upsell")
  });
  return { items: data };
};

export const UpsellCardList = ({
  cards
}: React.ComponentProps<"div"> & {
  cards: ProductCardType[];
}) => {
  const cardsLength = cards.length;
  const cardListWrapper = clsx({
    [styles.cards_1]: cardsLength === 1,
    [styles.cards_2]: cardsLength === 2,
    [styles.cards_3]: cardsLength === 3
  });
  return (
    <div data-layout="page" className={cardListWrapper}>
      {cards.map((card, key) => (
        <ProductCard key={card?.id || key} product={card} related />
      ))}
    </div>
  );
};

const CartUpsell = () => {
  const t = useTranslations();
  const { items } = useUpsell();
  if (!items || items.length < 1) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("cart.upsell.title")}</p>
      <UpsellCardList cards={items.slice(0, 3)} />
    </div>
  );
};

export default CartUpsell;
