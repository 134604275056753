"use client";

import Minus from "@/components/icons/Minus";
import Plus from "@/components/icons/Plus";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import useUpdateQuantity from "@/lib/centra/hooks/useSelection/cart/updateQuantity";
import { useCallback, useEffect, useState } from "react";
import styles from "./quantity-selector.module.css";

interface IQuantitySelectorProps {
  quantity: number;
  totalProductQuantity: number;
  line: string;
}

const getDecreasedQuantity = (quantity: number) =>
  quantity === 0 ? 0 : quantity - 1;

const QuantitySelector = ({ quantity, line }: IQuantitySelectorProps) => {
  const {
    updateQuantity: { mutateAsync }
  } = useUpdateQuantity();

  const [optimisticQuantity, setOptimisticQuantity] = useState(quantity);

  // Sync optimistic quantity with actual quantity on real update
  useEffect(() => {
    setOptimisticQuantity(quantity);
  }, [quantity]);

  // Override the mutation function to handle rollback if error
  const updateQuantityMutation = useCallback(
    async (args: { line: string; newQuantity: number }) => {
      try {
        await mutateAsync(args);
      } catch (error) {
        // Rollback optimistic state
        setOptimisticQuantity(quantity);
      }
    },
    [quantity, mutateAsync]
  );

  const debouncedUpdateQuantity = useDebouncedCallback(
    updateQuantityMutation,
    250
  );

  const increase = () => {
    const newQuantity = optimisticQuantity + 1;
    setOptimisticQuantity(newQuantity);
    debouncedUpdateQuantity({
      line,
      newQuantity
    });
  };

  const decrease = () => {
    const newQuantity = getDecreasedQuantity(optimisticQuantity);
    setOptimisticQuantity(newQuantity);

    debouncedUpdateQuantity({
      line,
      newQuantity
    });
  };

  return (
    <span className={styles["quantity-container"]}>
      <button type="button" title="Decrease" onClick={decrease}>
        <Minus />
      </button>
      <p>{optimisticQuantity}</p>
      <button type="button" title="Increase" onClick={increase}>
        <Plus />
      </button>
    </span>
  );
};

export default QuantitySelector;
