"use client";
import useLocation from "@/lib/centra/hooks/useLocation";
import { useCart } from "@/lib/centra/hooks/useSelection";
import {
  convertCartToGTagItem,
  eventViewCart,
} from "@/lib/utils/Analytics/Functions";
import { useEffect, useState } from "react";
import CartContent from "./CartContent";

const Cart = () => {
  const { items, modal, cart } = useCart();
  const [eventSent, setEventSent] = useState(false);
  const { currency } = useLocation();

  useEffect(() => {
    if (eventViewCart && modal.isOpen && !eventSent) {
      setEventSent(true);
      eventViewCart({
        currency: currency || "",
        items: items.map((item) => convertCartToGTagItem(item)),
        value: cart.summary?.prices.totalAsNumber,
      });
    }
  }, [
    modal.isOpen,
    items,
    currency,
    cart.summary?.prices?.totalAsNumber,
    eventSent,
  ]);

  useEffect(() => {
    if (!modal.isOpen) {
      setEventSent(false);
    }
  }, [modal.isOpen]);

  return <CartContent />;
};

export default Cart;
