import env from "@/env.mjs";
import { useAuth } from "@/lib/centra/hooks/useAuth";
import { serverFetchPCByIds } from "@/lib/centra/serverFunctionsCentra";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { ofetch } from "ofetch";
import { useMemo } from "react";
import { useHydrateLocalWishlist, useLocalWishlist } from "./useLocalWishlist";

const centraFetch = ofetch.create({
  baseURL: env.NEXT_PUBLIC_CENTRA_CHECKOUT_API
});

export interface CentraWishlistResponse {
  token: string;
  wishlist: {
    items: Array<{ wishlistItem: number; product: number }>;
    isDefault: boolean;
    /**
     * Wishlist count
     */
    wishlist: number;
  };
}

export const useWishlistKey = () => {
  const token = usePersistedStore((store) => store.token);
  return ["wishlist", token] as const;
};

export const useCentraWishlist = () => {
  const token = usePersistedStore((store) => store.token);
  const wishlistKey = useWishlistKey();
  const { isLoggedIn } = useAuth();
  const query = useQuery({
    queryKey: wishlistKey,
    queryFn: async ({ signal }) => {
      const response = await centraFetch<CentraWishlistResponse>(
        "/customer/wishlists/0",
        {
          headers: {
            "API-Token": token
          },
          signal
        }
      );

      return response.wishlist;
    },
    enabled: isLoggedIn && !!token
  });

  return query;
};

export const useWishlist = () => {
  const local = useLocalWishlist();
  useHydrateLocalWishlist();

  const { data, isLoading } = useCentraWishlist();
  const queryClient = useQueryClient();
  const token = usePersistedStore((store) => store.token);
  const { isLoggedIn, isLoading: authLoading } = useAuth();
  const localItems = useMemo(
    () => Array.from(local.items.values()),
    [local.items]
  );
  /**
   * Wishlist sync with Centra
   */
  useQuery({
    queryKey: [
      "wishlist",
      { isLoggedIn, token, items: localItems },
      "centra-hydrate"
    ],
    queryFn: async () => {
      if (!isLoggedIn) return null;
      const items = localItems;
      if (items.length === 0) return null;
      // Sync
      const promises = items.map(async (itemId) => {
        const response = await centraFetch<CentraWishlistResponse>(
          `/customer/wishlists/0/items/${itemId}`,
          {
            method: "POST",
            headers: {
              "API-Token": token
            }
          }
        );
        return response;
      });

      const responses = await Promise.allSettled(promises);
      queryClient.invalidateQueries({ queryKey: ["wishlist", token] });
      // Wipe local to avoid excessive syncing with Centra
      local.clear();
      return responses;
    },
    enabled: isLoggedIn && local.items.size >= 1 && !!token
  });

  if (isLoggedIn) {
    return {
      ...local,
      items: data?.items ? data.items.map((i) => String(i.product)) : [],
      isLoading
    };
  }

  return {
    ...local,
    items: localItems,
    isLoading: authLoading
  };
};

const useAddToWishlist = (itemId?: string) => {
  const wishlistKey = useWishlistKey();
  const token = usePersistedStore((store) => store.token);
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuth();

  const mutation = useMutation({
    mutationKey: ["wishlist", itemId, token, isLoggedIn],
    mutationFn: async () => {
      if (!itemId || !token || !isLoggedIn) return;

      const response = await centraFetch(
        `/customer/wishlists/0/items/${itemId}`,
        {
          method: "POST",
          headers: {
            "API-Token": token
          }
        }
      );

      return response;
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: ["wishlist"] });

      const oldData =
        queryClient.getQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey
        );

      if (oldData) {
        queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey,
          {
            ...oldData,
            items: [
              ...oldData.items,
              {
                wishlistItem: oldData.items.length + 1,
                product: Number(itemId)
              }
            ]
          }
        );
      }

      return {
        oldData
      };
    },
    onError: (_, __, ctx) => {
      ctx?.oldData &&
        queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey,
          ctx.oldData
        );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["wishlist"] });
    }
  });

  return mutation;
};

const useRemoveFromWishlist = (itemId?: string) => {
  const wishlistKey = useWishlistKey();
  const token = usePersistedStore((store) => store.token);
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuth();

  const mutation = useMutation({
    mutationKey: ["wishlist", itemId, token, isLoggedIn],
    mutationFn: async () => {
      if (!itemId || !token || !isLoggedIn) return;

      const response = await centraFetch(
        `/customer/wishlists/0/items/${itemId}`,
        {
          method: "DELETE",
          headers: {
            "API-Token": token
          }
        }
      );

      return response;
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: wishlistKey });

      const oldData =
        queryClient.getQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey
        );

      if (oldData) {
        queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey,
          {
            ...oldData,
            items: oldData.items.filter(
              (i) => String(i.product) !== String(itemId)
            )
          }
        );
      }

      return {
        oldData
      };
    },
    onError: (_, __, ctx) => {
      ctx?.oldData &&
        queryClient.setQueryData<CentraWishlistResponse["wishlist"]>(
          wishlistKey,
          ctx.oldData
        );
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["wishlist"] });
    }
  });

  return mutation;
};

export const useInWishlist = (itemId?: string) => {
  const items = useWishlist().items;

  return !!itemId && items.includes(itemId);
};

export const useWishlistProduct = (itemId?: string) => {
  useHydrateLocalWishlist();

  const addLocal = useLocalWishlist((state) => state.add);
  const removeLocal = useLocalWishlist((state) => state.remove);
  const has = useInWishlist(itemId);

  const add = useAddToWishlist(itemId);
  const remove = useRemoveFromWishlist(itemId);
  const pending = useIsMutating({ mutationKey: ["wishlist", itemId] });
  const { isLoggedIn } = useAuth();

  return {
    has,
    add: !isLoggedIn ? () => addLocal(itemId) : () => add.mutate(),
    remove: !isLoggedIn ? () => removeLocal(itemId) : () => remove.mutate(),
    isPending: !!pending
  };
};

export const useWishlistItems = ({ limit }: { limit?: number } = {}) => {
  useHydrateLocalWishlist();

  const { items, isLoading } = useWishlist();

  const { data, isLoading: itemsLoading } = useQuery({
    queryKey: ["wishlist", { items, limit }],
    queryFn: async () => {
      const limitedItems = limit ? items.slice(0, limit) : items;
      const products = await serverFetchPCByIds(limitedItems);
      return products;
    },
    enabled: items.length > 0
  });

  return {
    data,
    isLoading: isLoading || itemsLoading
  };
};
