import styles from "./index.module.css";

interface SkeletonProps extends React.ComponentProps<"div"> {
  height: string;
  absolute?: boolean;
}
const Skeleton = ({ height, absolute, style, ...rest }: SkeletonProps) => {
  return (
    <div
      className={styles.skeleton}
      {...rest}
      style={{
        ...style,
        animation: "skeleton-loading 1s linear infinite alternate",
        height,
        position: absolute ? "absolute" : "relative"
      }}
    />
  );
};

export default Skeleton;
